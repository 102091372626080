<template>
  <div v-if="progressName" class="progress-status">
    <div class="progress-status-loading">
      <img src="@/assets/img/reports/sources-sync-loading.png" alt="loading" />
    </div>
    <div class="progress-status-text">{{ progressName }}</div>
  </div>
</template>
<script lang="ts" setup>
import { last } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { progressStatusType } from '@/config/global'
import { useEntityStore } from '@/stores/modules/entity'

const { t } = useI18n()
const entityStore = useEntityStore()

const progressName = computed(() => {
  const lastProgress = last(entityStore.entityDetail?.progress?.statusList ?? [])
  if (!lastProgress) {
    return ''
  }

  switch (lastProgress.type) {
    case progressStatusType.SOURCE_FETCHING:
      return t('progressStatus.syncingSources')
    case progressStatusType.SOURCE_DELETING:
      return t('progressStatus.deletingSources')
    case progressStatusType.TRANCATION_AUTOMATING:
    case progressStatusType.GAIN_LOSS_AUTOMATING:
    case progressStatusType.TRADE_AUTOMATING:
    case progressStatusType.BUSINESS_DATA_AUTOMATING:
    case progressStatusType.COUNTERPARTY_TRANSFORMER_AUTOMATING:
    case progressStatusType.DERIVATIVE_FACT_TRANSFORMER_AUTOMATING:
    case progressStatusType.DERIVATIVE_ADJUSTMENT_AUTOMATING:
      return t('progressStatus.executingRules')
    case progressStatusType.POSTING:
      return t('progressStatus.postingToLedger')
    case progressStatusType.RECALCULATE_ADJUSTMENT:
    case progressStatusType.RECALCULATE_COST_BASIS:
      return t('progressStatus.recalculatingCostBasis')
    case progressStatusType.REPORTING:
      return t('progressStatus.regeneratingReports')
    case progressStatusType.JOURNAL_GROUP:
      return t('progressStatus.regeneratingBusinessEvents')
    case progressStatusType.RECONCILIATION_AUTOMATING:
      return t('progressStatus.performingAutomaticReconciliation')
    case progressStatusType.REFRESH_BALANCE:
      return t('progressStatusToast.refreshBalance')
    case progressStatusType.ENTITY_ACCOUNT_DELETING:
      return t('progressStatus.deletingAccount')
    default:
      return ''
  }
})
</script>
<style lang="scss">
.progress-status {
  border-radius: 22px;
  background: #f2f5ff;
  padding: 6px 12px 6px 6px;
  display: flex;
  align-items: center;

  .progress-status-loading {
    img {
      width: 12px;
      height: 12px;
      display: block;
      margin-right: 6px;
      animation: loading-rotate 2s linear infinite;
    }
  }

  .progress-status-text {
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
</style>
