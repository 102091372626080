export const progressStatusType = {
  SOURCE_FETCHING: 'SOURCE_FETCHING',
  RECALCULATE_ADJUSTMENT: 'RECALCULATE_ADJUSTMENT',
  SOURCE_DELETING: 'SOURCE_DELETING',
  TRANCATION_AUTOMATING: 'TRANCATION_AUTOMATING',
  TRADE_AUTOMATING: 'TRADE_AUTOMATING',
  GAIN_LOSS_AUTOMATING: 'GAIN_LOSS_AUTOMATING',
  BUSINESS_DATA_AUTOMATING: 'BUSINESS_DATA_AUTOMATING',
  COUNTERPARTY_TRANSFORMER_AUTOMATING: 'COUNTERPARTY_TRANSFORMER_AUTOMATING',
  DERIVATIVE_FACT_TRANSFORMER_AUTOMATING: 'DERIVATIVE_FACT_TRANSFORMER_AUTOMATING',
  DERIVATIVE_ADJUSTMENT_AUTOMATING: 'DERIVATIVE_ADJUSTMENT_AUTOMATING',
  JOURNAL_GROUP: 'JOURNAL_GROUP',
  POSTING: 'POSTING',
  RECALCULATE_COST_BASIS: 'RECALCULATE_COST_BASIS',
  REPORTING: 'REPORTING',
  RECONCILIATION_AUTOMATING: 'RECONCILIATION_AUTOMATING',
  REFRESH_BALANCE: 'REFRESH_BALANCE',
  ENTITY_ACCOUNT_DELETING: 'ENTITY_ACCOUNT_DELETING'
}
