// useWebSocketHook.ts

interface WebSocketOptions {
  url: string
  reconnectInterval?: number
  heartbeatInterval?: number
  // eslint-disable-next-line no-unused-vars
  onMessage?: (message: string) => void
}

export function useWebSocketHook(options: WebSocketOptions) {
  const socket = ref<WebSocket | null>(null)
  const isConnected = ref(false)
  const isUserDisconnecting = ref(false)
  // let heartbeatTimer: NodeJS.Timer | null = null
  let reconnectAttempts = 0

  // const startHeartbeat = () => {
  //   heartbeatTimer = setInterval(() => {
  //     if (isConnected.value && socket.value) {
  //       socket.value.send('ping')
  //     }
  //   }, options.heartbeatInterval || 5000)
  // }

  // const stopHeartbeat = () => {
  //   if (heartbeatTimer !== null) {
  //     clearInterval(heartbeatTimer)
  //   }
  // }

  const connect = () => {
    isUserDisconnecting.value = false
    console.log('connect url', options.url)
    socket.value = new WebSocket(options.url)

    socket.value.onopen = () => {
      isConnected.value = true
      console.log('WebSocket connected')
      // startHeartbeat()
    }

    socket.value.onmessage = (event) => {
      console.log('WebSocket message received:', event.data)
      if (options.onMessage) {
        options.onMessage(JSON.parse(event.data))
      }
    }

    socket.value.onclose = (event) => {
      isConnected.value = false
      console.log('WebSocket disconnected:', event.reason)
      let isTimeoutDisconnect = false

      // aws websocket 满10分钟主动断连 需要重试
      if (event.reason === 'Going away') {
        isTimeoutDisconnect = true
      }

      if (isTimeoutDisconnect || (!isUserDisconnecting.value && reconnectAttempts < 10000)) {
        // 断开连接后，如果不是用户主动断开连接，且重连次数未达到限制，则尝试自动重连
        reconnectAttempts += 1
        setTimeout(() => {
          console.log('Reconnecting...')
          connect()
        }, options.reconnectInterval || 30000)
      }
    }
  }

  const disconnect = () => {
    if (socket.value) {
      console.log('disconnect', options.url)
      isUserDisconnecting.value = true
      socket.value.close()
      // stopHeartbeat()
    }
  }

  const sendMessage = (message: string) => {
    if (isConnected.value && socket.value) {
      socket.value.send(message)
    } else {
      console.error('WebSocket is not connected.')
    }
  }

  // 需要自己手动调用 connect 来启动连接
  return {
    isConnected,
    sendMessage,
    connect,
    disconnect
  }
}
